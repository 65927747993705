<template>
	<div id="hid-main" v-on:click="() => { if(!musicBackground.musicAutoplay && !musicBackground.musicState) musicBackground.musicState = true }">

		<!-- Sfondo della pagina -->
		<div id="hid-background">
			<div id="hid-background-a" v-bind:style="{ 'background-image': 'url(' + this.mediaBackgroundA.imageSource + ')', 'opacity': this.mediaBackgroundA.imageOpacity }"></div>
			<div id="hid-background-b" v-bind:style="{ 'background-image': 'url(' + this.mediaBackgroundB.imageSource + ')', 'opacity': this.mediaBackgroundB.imageOpacity }"></div>
			<div id="hid-background-overlay"></div>
		</div>

		<!-- Body della pagina -->
		<div id="hid-container" class="py-1">
			<img src="./assets/hytaleit-logo.png" style="height: 200px">

			<!-- Contatore grande -->
			<hid-time timeInvert v-bind:timeLabel="$t('message.counters.anniversary')" v-bind:time="timeTrailerAnniversary" class="my-4" /> 
			
			<!-- Box contatori -->
			<hid-box>
				<!-- Titolo box -->
				<h1 class="text-normal headline-primary">
					{{ $t('message.counters.headline') }}
				</h1>
				<hr/>

				<hid-time timeInvert class="mb-2" timeSimple v-bind:timeLabel="$t('message.counters.year')" v-bind:time="timeYear" /> 
				<hr/>

				<hid-time class="mb-2" timeSimple v-bind:timeLabel="$t('message.counters.trailer')" v-bind:time="timeTrailerOriginal" /> 
				<hid-time class="mb-0" timeSimple v-bind:timeLabel="$t('message.counters.blog')" v-bind:time="timeBlog" /> 
				<hr/>

				<!-- Messaggio info beta -->
				<em>
					{{ $t('message.beta') }}
				</em>
			</hid-box>

			<!-- footer -->
			<div id="hid-footer" class="mt-4" v-show="!pageFrameless">
				<strong>&copy;{{ new Date().getFullYear() }} <a href="https://hytale.it/">Hytale Italia</a></strong>
				<div class="mt-1 mb-3">
					<icon v-bind:icon="['fa', 'heart']" class="heart mr-2" /> 
					<span>{{ $t('message.madeby') }} <a href="https://emax.dev" title="EmaxDEV">Emax</a></span>
				</div>
				<div class="mt-4">
					<em>This site is not affiliated with Hytale or Hypixel Studios</em>
					<br/>
					<em>Some media are trademarked property of Hypixel Studios!</em>
				</div>
			</div>
		</div>

		<!-- Musicbox info -->
		<div id="hid-musicbox">
			<h4 class="text-normal headline-secondary mb-1">
				{{ $t('message.musicbox') }}
			</h4>
			<a v-bind:class="{ aInactive: $i18n.locale != 'it' }" v-bind:href="musicBox.musicLink" target="_blank">
				<strong>{{ musicBox.musicTitle }}</strong>
			</a>
			<div class="mt-1">
				<span class="mr-2" style="cursor: pointer; user-select: none;" v-on:click="() => { if ((musicBackground.musicVolume += 5) > 100) musicBackground.musicVolume = 5; }">
					<icon v-bind:icon="['fa', 'volume-up']"/>
					<span class="ml-1">{{ musicBackground.musicVolume }}%</span>
				</span>
				<span class="mr-2">
					<button v-on:click="musicBackground.musicState = !musicBackground.musicState">
						<icon v-bind:icon="['fa', 'pause']" v-if="musicBackground.musicState == true"/>
						<icon v-bind:icon="['fa', 'play']" v-if="musicBackground.musicState == false"/>
					</button>
					<button v-on:click="pageMusic()">
						<icon v-bind:icon="['fa', 'forward']"/>
					</button>
				</span>
			</div>

			<!-- Musicbox player -->
			<video ref="hid-musicplayer" v-on:ended="pageMusic()" controls></video>
		</div>

		<!-- Mediabox info -->
		<div id="hid-mediabox">
			<h4 class="text-normal headline-secondary mb-1">
				{{ $t('message.mediabox') }}
			</h4>
			<a v-bind:class="{ aInactive: $i18n.locale != 'it' }" v-bind:href="mediaBox.mediaLink" target="_blank">
				<strong>{{ mediaBox.mediaTitle }}</strong>
			</a>
			<div class="mt-1">
				<span class="mr-2">
					{{ mediaBox.mediaRating == 0 ? 5 : mediaBox.mediaRating }}/5
					<icon v-bind:icon="['fa', 'star']" />
				</span>
				<span class="mr-0">
					{{ mediaBox.mediaViews }}
					<icon v-bind:icon="['fa', 'eye']" />
				</span>
			</div>
		</div>

	</div>
</template>

<script>
import HIDBox from './components/HIDBox.vue'
import HIDTime from './components/HIDTime.vue'

export default {
	name: 'App',
	components: {
		"hid-box": HIDBox,
		"hid-time": HIDTime
	},
	data: () => {
		return {
			pageFrameless: false,

			timeTrailerAnniversary: "2024-12-13 17:59:54",
			timeTrailerOriginal: "2018-12-13 17:59:54",
			timeBlog: "2024-06-17 20:50:00",

			musicRegistry: [],
			mediaRegistry: [],

			musicBackground: {
				musicSource: null,
				musicVolume: 100,
				musicState: false,
				musicAutoplay: null,
			},

			mediaBackgroundA: {
				imageOpacity: 1.0,
				imageSource: null,
			},
			mediaBackgroundB: {
				imageOpacity: 1.0,
				imageSource: null,
			},

			musicBox: {
				musicTitle: null,
				musicLink: null
			},

			mediaBox: {
				mediaRating: null,
				mediaViews: null,
				mediaTitle: null,
				mediaLink: null,
			},
		}
	},
	computed: {
		timeYear() {
			return moment("2025-01-01 00:00:00");
		},
		pagePlayer() {
			return this.$refs["hid-musicplayer"];
		}
	},
	methods: {
		pageFetch(pageURL, pageCallback) {
			let pageRequest = new XMLHttpRequest();
			pageRequest.open('GET', pageURL, true);
			pageRequest.onreadystatechange = () => {
				if (pageRequest.readyState == 4 && pageRequest.status == 200) {
					pageCallback(JSON.parse(pageRequest.responseText));
				}
			}
			pageRequest.send();
		},
		pageMusic() {
			var mediaRandom = this.mediaRegistry.filter((media) => media["media_tags"].includes("ost"));
			mediaRandom = mediaRandom[parseInt(Math.random() * mediaRandom.length)];

			// HardFIX
			mediaRandom["media_caption"] = mediaRandom["media_caption"].replace("Hytale OST - ", "");

			this.musicBox.musicTitle = mediaRandom["media_caption"];
			this.musicBox.musicLink = mediaRandom["media_url"];
	
			var mediaPlayer = this.pagePlayer;
			mediaPlayer.src = mediaRandom["media_source"];
			var mediaPromise = mediaPlayer.play();

			if (mediaPromise !== undefined) {
				mediaPromise.then(() => {
					this.musicBackground.musicState = true;
				}).catch((e) => {
					this.musicBackground.musicState = false;
					this.musicBackground.musicAutoplay = false;
				});
			}			
		},
		pageBackground() {
			var mediaRandom = this.mediaRegistry.filter((media) => media["media_tags"].includes("wallpaper"));
			mediaRandom = mediaRandom[parseInt(Math.random() * mediaRandom.length)];

			this.mediaBackgroundA.imageOpacity = 1;
			this.mediaBackgroundB.imageOpacity = 0;
			this.mediaBackgroundB.imageSource = mediaRandom["media_source"];

			this.mediaBox.mediaRating = mediaRandom["media_rating"];
			this.mediaBox.mediaViews = mediaRandom["media_views"];
			this.mediaBox.mediaTitle = mediaRandom["media_caption"];
			this.mediaBox.mediaLink = mediaRandom["media_url"];
			
			setTimeout(() => {
				var mediaFader = setInterval(() => {

					if ((this.mediaBackgroundB.imageOpacity += 0.005) > 1) {
						this.mediaBackgroundA.imageSource = mediaRandom["media_source"];
						setTimeout(() => {
							this.mediaBackgroundA.imageOpacity = 1;
							this.mediaBackgroundB.imageOpacity = 0;
							setTimeout(this.pageBackground, 8000);
						}, 500);
						clearInterval(mediaFader);
					}
				}, 5);
			}, 500);
		},
	},
	watch: {
		musicBackground: {
			handler(musicBackground) {
				var mediaPlayer = this.pagePlayer;

				mediaPlayer.volume = musicBackground.musicVolume / 100;

				if (musicBackground.musicState == true) {
					musicBackground.musicAutoplay = true;
					mediaPlayer.play();
				}
				if (musicBackground.musicState == false) {
					mediaPlayer.pause();
				}
			},
			deep: true
		}
	},
	created() {

		// Ottengo la lista dei media presenti nella galleria e inizializzo il task di cambiamento del background
		this.pageFetch("https://scripts.hytale.it/script-media-serve.php", (mediaRegistry) => {
			this.mediaRegistry = mediaRegistry;
			this.pageBackground();
			this.pageMusic();
		});

		// Se viene richiesta la pagina frameless la servo
		var pageParameters = new URLSearchParams(window.location.search);
		if (pageParameters.has("frameless")) {
			this.$i18n.locale = "it";
			this.pageFrameless = true;
		}
	}
}
</script>

<style lang="scss">
	@import url("https://cdn.hytale.it/fonts/penumbraserif/stylesheet.css");
	@import url("https://cdn.hytale.it/fonts/notosans/stylesheet.css");

	@import "assets/styles/spacing.css";

	html, body {
		padding: 0px;
		margin: 0px;
		
		height: 100%;	
		width: 100%;
	}

	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
		font-weight: bold;
		
		color:#DDD;

		padding: 0px;
		margin: 0px;
	}

	.text-heading {
		font-family: 'Noto Sans', sans-serif;
		font-weight: bold;
	}

	.text-normal {
		font-family: "Penumbra Serif Std";
		font-weight: bold;
	}

	.headline-secondary {
		background: linear-gradient(#dae9f2, #adcde2);
		background-clip: text;

		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
	}

	.headline-primary {
		background: linear-gradient(#ffe98d,#e19f27);
		background-clip: text;
		
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
	}

	hr {
		border: solid rgba(255, 255, 255, 0.15);
		border-width: 0px 0px 1px 0px;
	}

	a {
		color: #D29F32;
		text-decoration: none;
	}

	a.disable {
		pointer-events: none;
		opacity: 0.4;
	}

	.aInactive {
		pointer-events: none;
		cursor: default;
	}

	button {
		color: inherit;
		cursor: pointer;
		background: none;
		border: none;
	}
	
	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}

	::-webkit-scrollbar-thumb {
		background: #213557;
		border: 0px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #d29f32;
	}

	::-webkit-scrollbar-track {
		background: #0e1726;
		border: 0px;
	}

	::-webkit-scrollbar-track:hover {
		background: #152339;
	}

	::-webkit-scrollbar-corner {
		background: transparent;
	}

	#hid-main {
		font-family: "Noto Sans", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;

		background: #202020;
		color: #B7CEDD;

		height: 100%;	
		width: 100%;
	
		display: flex;
		align-items: center;
		justify-content: center
	}

	#hid-musicbox {
		position: fixed;
		z-index: 5;
		bottom: 20px;
		left: 20px;

		text-align: left;
		opacity: 0.7;		
	}

	#hid-musicbox video {
		display: none;
		width: 250px;
	}

	#hid-mediabox {
		position: fixed;
		z-index: 5;
		bottom: 20px;
		right: 20px;

		text-align: right;
		opacity: 0.7;
	}

	#hid-container {
		padding-bottom: 50px;
		padding-top: 50px;

		margin: auto;
		width: 600px;
		z-index: 2;
	}

	#hid-background {
		z-index: 1;
	}

	#hid-background, #hid-background-a, #hid-background-b, #hid-background-overlay {
		position: fixed;
		bottom: 0px;
		right: 0px;
		left: 0px;
		top: 0px;
	}

	#hid-background-a, #hid-background-b {
		background-attachment: fixed;
		background-position: center;
		background-size: cover;

		transform: scale(1.1);
		filter: blur(3px);
	}

	#hid-background-a {
		z-index: 1;
	}

	#hid-background-b {
		z-index: 2;
	}

	#hid-background-overlay {
		z-index: 3;
	}

	#hid-background-overlay {
		background: radial-gradient(circle, rgba(14, 23, 38, 0.6) 0%, rgba(14, 23, 38, 1) 100%);
	}
	
	#hid-footer {
        text-shadow: 0 0 1px black, 
					 0 0 1px black, 
					 0 0 1px black, 
					 0 0 1px black; 
	}
	
	#hid-footer em {
		opacity: 0.6;
		font-size: 0.9em;
	}

	.heart {
		color: red;
		animation: heart-animation .25s infinite alternate;
		transform-origin: center;
	}

	@keyframes heart-animation {
		to { transform: scale(1.2); }
	}

	@media only screen and (max-width: 850px) { 
		#hid-musicbox {
			display: none;
		}

		#hid-mediabox {
			display: none;
		}
	}

</style>
