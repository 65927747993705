<template>
	<div class="hid-box">
		<img src="../assets/hytaleit-corner.png" />
		<img src="../assets/hytaleit-corner.png" />
		<img src="../assets/hytaleit-corner.png" />
		<img src="../assets/hytaleit-corner.png" />
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'HIDBox'
}
</script>

<style scoped lang="scss">

	.hid-box {
		position: relative;

		padding: 10px 30px 10px 30px;
		
		background: #0E1726;
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5), inset 0 0 0 2px #4e5765;

		border: 2px solid #15243A;			
	}

	.hid-box > img:nth-child(1),
	.hid-box > img:nth-child(2),
	.hid-box > img:nth-child(3),
	.hid-box > img:nth-child(4) {
		position: absolute;
		height: 14px;
		width: 14px;
	}

	.hid-box > img:nth-child(1) {
		transform: rotate(180deg);
		left: 0px;
		top: 0px;
	}

	.hid-box > img:nth-child(2) {
		transform: rotate(270deg);
		right: 0px;
		top: 0px;
	}

	.hid-box > img:nth-child(3) {
		transform: rotate(90deg);
		bottom: 0px;
		left: 0px;
	}

	.hid-box > img:nth-child(4) {
		transform: rotate(0deg);
		bottom: 0px;
		right: 0px;
	}

</style>
