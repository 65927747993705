<template>
	<div id="display">
		<template v-if="timeSimple == true">
			<h3 class="headline-secondary text-normal">
				{{ timeLabel }}
			</h3>
			<h4>
				<template v-for="(timeEntry, timeIndex) in timeData">
					<template v-if="timeEntry.timeValue >= 0">
						<span v-bind:key="timeIndex" class="test">
							{{ timeEntry.timeValue }}
							{{ $tc(timeEntry.timeLabel, timeEntry.timeValue) }}</span>
					</template>
				</template>
			</h4>
		</template>
		<template v-if="timeSimple == false">
			<div id="heading">
				<span class="decoration left"></span>
				<h1 class="headline-secondary text-normal">
					{{ timeLabel }}
				</h1>
				<span class="decoration right"></span>
			</div>
			<div class="time-wrapper">
				<div class="time">
					<template v-for="(timeEntry, timeIndex) in timeData">
						<template v-if="timeEntry.timeValue >= 0">
							<div class="block" v-bind:key="timeIndex">
								<div class="digits text-normal headline-primary">
									{{ timeEntry.timeValue | two_digits }}
								</div>
								<div class="label">
									{{ $tc(timeEntry.timeLabel, timeEntry.timeValue) }}
								</div>
							</div>
						</template>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'HIDTime',
	props: {
		time: {
			default: "2000-01-01 00:00:00"
		},
		timeLabel: {
			type: String,
			default: "Time display"
		},
		timeSimple: {
			type: Boolean,
			default: false
		},
		timeInvert: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			timeUpdater: null,
			timeData: [
				{
					timeLabel: "message.time.year",
					timeValue: 0
				},
				{
					timeLabel: "message.time.month",
					timeValue: 0
				},
				{
					timeLabel: "message.time.day",
					timeValue: 0
				},
				{
					timeLabel: "message.time.hour",
					timeValue: 0
				},
				{
					timeLabel: "message.time.minute",
					timeValue: 0
				},
				{
					timeLabel: "message.time.second",
					timeValue: 0
				}
			]
		}
	},
	methods: {
		timeUpdate() {
			var timeCurrent = moment();
			var timeArrival = moment(this.time);

			var timeDelta = this.timeInvert ? 
				timeArrival.diff(timeCurrent) : 
				timeCurrent.diff(timeArrival);

			if (this.timeInvert && timeDelta < 0) {
				this.timeData.forEach((timeEntry) => {
					timeEntry.timeValue = 0;
				});
			} else {


				var timeInterval = moment.duration(timeDelta);
				this.timeData[0].timeValue = timeInterval.years();
				this.timeData[1].timeValue = timeInterval.months();
				this.timeData[2].timeValue = timeInterval.days();
				this.timeData[3].timeValue = timeInterval.hours();
				this.timeData[4].timeValue = timeInterval.minutes();
				this.timeData[5].timeValue = timeInterval.seconds();

				this.timeData.some((timeEntry, timeIndex) => {
					if (timeIndex < 2) {
						if (timeEntry.timeValue == 0) {
							timeEntry.timeValue = -1;
							return false;
						} else {
							return true;
						}
					}
				});

			}
			
		}
	},
	created() {
		this.timeUpdater = setInterval(this.timeUpdate, 300);
	},
	destroyed() {
		clearInterval(this.timeUpdater);
	}
}
</script>
<style>
	.test::after {
		content: ",";
	}
	.test:last-child::after {
		content: "";
	}

	.test {
		color: #CCC;
	}

	#display {
		display: block;
		width: 100%;
	}

	#display #heading {
		display: flex;
		margin-bottom: 1em;

		justify-content: center;
		align-items: center;
	}

	#display #heading h1 {
		display: inline-block;

		margin-right: 1.5em;
		margin-left: 1.5em;

		font-size: 1.3em;
	}

	#display #heading .decoration {
		content: "";
		position: relative;
		display: inline-block;
		vertical-align: middle;
		height: 1px;
		flex: 1;
	}

	#display #heading .decoration.left {
		margin-right: 10px;
		background: linear-gradient(to right, rgba(96, 81, 43, 0) 0%, #60512b 25%, #60512b 100%);
	}

	#display #heading .decoration.left::before {
		right: -10px;
	}

	#display #heading .decoration::before {
		content: "";
		position: absolute;
		top: 50%;
		display: block;
		width: 11px;
		height: 11px;
		background: url("../assets/hytaleit-headline.png") 50% 50% no-repeat;
		background-size: contain;
		transform: translateY(-50%);
	}

	#display #heading .decoration.right {
		margin-left: 10px;
		background: linear-gradient(to left, rgba(96, 81, 43, 0) 0%, #60512b 25%, #60512b 100%);
	}

	#display #heading .decoration.right::before {
		left: -10px;
		transform: translateY(-50%) rotate(180deg);
	}

	.time-wrapper {
		padding: .3em;
		border: 1px solid #60512b;
		border-radius: 5px;
	}

	.time {
		position: relative;
		width: 100%;
		height: 90px;
		box-sizing: border-box;
		
		background: url("../assets/hytaleit-countdown.png") 50% 50% no-repeat;
		background-size: 100% 100%;

		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;

		white-space: nowrap;
	}

	.time .block {
		flex: 1 1 0px;
	

		border-right: 1px solid #3e423c;
	}

	.time .block:last-child {
		border: 0;
	}

	.digits, .label {
		color: #a17831;
		text-transform: uppercase;	
	}

	.digits {
		font-size: 2.3em;
	}

	.label {
		font-size: 0.7em;
	}

</style>