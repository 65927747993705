import Vue from 'vue'

/* ================================== MOMENT ================================== */

import moment from 'moment'
window.moment = moment;

/* ================================== FONT AWESOME ================================== */

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab);
library.add(fas);

Vue.component('icon', FontAwesomeIcon)

/* ================================== LOCALIZATION ================================== */

import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'en',
	messages: {
		en: {
			message: {
				madeby: "Made by",
				mediabox: "Current Background",
				musicbox: "Current OST",
				time: {
					year: "year | years",
					month: "month | months",
					day: "day | days",
					hour: "hour | hours",
					minute: "minute | minutes",
					second: "second | seconds",
				},
				counters: {
					headline: "Counters",
					anniversary: "Trailer Anniversary",
					trailer: "Time elapsed since the trailer",
					blog: "Time elapsed since the last blogpost",
					postcard: "Countdown to the next postcard",
					year: "Countdown to 2025",
				},
				beta: "The beta release date is still unknown"
			}
		},
		it: {
			message: {
				madeby: "Fatto da",
				mediabox: "Sfondo attuale",
				musicbox: "Musica attuale",
				time: {
					year: "anno | anni",
					month: "mese | mesi",
					day: "giorno | giorni",
					hour: "ora | ore",
					minute: "minuto | minuti",
					second: "secondo | secondi",
				},
				counters: {
					headline: "Contatori",
					anniversary: "Anniversario del trailer",
					trailer: "Tempo trascorso dal trailer",
					blog: "Tempo trascorso dall'ultimo articolo",
					postcard: "Tempo mancante alla prossima Postcard",
					year: "Tempo mancante al 2025",
				},
				beta: "La data precisa della beta è ancora sconosciuta"
			}
		}
	}
})

var language = (navigator.language || navigator.userLanguage);
language = language.toLowerCase();

if (language == "it" || language == "it-it") {
	i18n.locale = "it";
}

/* ================================== FILTER ================================== */

Vue.filter('two_digits', function (value) {
	return String(value).padStart(2, "0");
});

/* ================================== VUE ================================== */

Vue.config.productionTip = false

import HIDate from './HIDate.vue'

new Vue({
	i18n,
	render: h => h(HIDate),
}).$mount('#hytaledate')
